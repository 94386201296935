(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:RefereeList
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('statsvots', statsvots);

  function statsvots($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/statsvots/:id', {id: '@_id'},{
      update: {
        method: 'PUT'
      }, insert: {
        method: 'POST'
      },
      query: {
        method: 'GET',
        isArray: true
      }
    });
  }
}());